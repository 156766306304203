<template>
  <div class="admin-view-container student-list-container" ref="studentContainer">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item style="width: 18%" label="姓名">
          <el-input placeholder="请输入内容" v-model="tableQuery.drvStudentName"></el-input>
        </el-form-item>
        <el-form-item style="width: 18%" label="身份证号">
          <el-input placeholder="请输入内容" v-model="tableQuery.drvStudentIdentityCard"></el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="vehicleType" style="width: 18%">
          <select-dict :dict="$dict.vehicleType" show-all v-model="tableQuery.vehicleType"></select-dict>
        </el-form-item>

        <el-form-item label="考试信息" style="width: 18%">
          <el-cascader v-model="vehicleType" :options="vehicleTypeOptions" @change="vehicleTypeChange"></el-cascader>
        </el-form-item>
        <el-form-item label="所属驾校" style="width: 18%">
          <select-dict-remote
            filterable
            show-all
            v-model="tableQuery.drvSchoolCode"
            :api="drvSchoolGetSchool"
            :query="{getType: 2}"
            value-field="drvSchoolCode"
            label-field="drvSchoolForShort"></select-dict-remote>
        </el-form-item>
        <el-form-item label="缴费情况" style="width: 18%">
          <select-dict v-model="tableQuery.policyStatus" :dict="$dict.policyStatus" show-all></select-dict>
        </el-form-item>
        <!-- <el-form-item label="是否转入" prop="transferStatusText">
          <el-select v-model="tableQuery.transferStatusText">
            <el-option label="全部" value=""></el-option>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="本外地人" style="width: 18%">
          <el-select v-model="tableQuery.residence">
            <el-option label="全部" value=""></el-option>
            <el-option label="无" value="1"></el-option>
            <el-option label="本地人" value="2"></el-option>
            <el-option label="外地人" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="报名时间" style="width: 18%">
          <el-date-picker
            v-model="dateValue"
            @change="datePickerChange"
            :default-time="['00:00:00', '23:59:59']"
            unlink-panels
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: auto; width: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <admin-space size="medium" class="admin-methods-btns" align="center" v-p="[20001]">
      <el-button icon="el-icon-download" size="medium" @click="openExport()" :loading="loading"> 导出考生信息 </el-button>
    </admin-space>

    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%" @cell-mouse-enter="mouseEnter" @mouseleave.native="mouseLeave">
        <el-table-column
          show-overflow-tooltip
          prop="enrollStartTime"
          label="报名时间"
          fixed
          :formatter="$formatter.date(true)"></el-table-column>
        <el-table-column show-overflow-tooltip prop="drvStudentName" label="姓名" fixed width="110">
          <template slot-scope="scope">
            <span style="display: flex; align-items: center; justify-content: space-between">
              {{ scope.row.drvStudentName }}
              <img src="/static/turn.png" alt="" v-if="scope.row.transferStatus" style="width: 20px; height: 20px" />
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="drvStudentIdentityCard"
          label="身份证号"
          width="130"
          fixed></el-table-column>
        <el-table-column show-overflow-tooltip label="归属地" prop="areaName" fixed></el-table-column>
        <el-table-column
          label="本外地人"
          prop="residence"
          fixed
          :formatter="$formatter.dict($dict.residence)"></el-table-column>
        <el-table-column show-overflow-tooltip prop="drvSchoolForShort" width="110" label="驾校名称" fixed></el-table-column>
        <el-table-column show-overflow-tooltip prop="vehicleType" label="业务类型" fixed></el-table-column>
        <!-- <el-table-column
          label="状态"
          prop="enrollStatus"
          :formatter="$formatter.dict($dict.enrollStatus)"
          fixed></el-table-column> -->
        <el-table-column show-overflow-tooltip prop="policyStatus" label="缴费状态" fixed>
          <template slot-scope="scope">
            <span :class="scope.row.policyStatus == 1 ? 'green-font' : scope.row.policyStatus == 2 ? 'red-font' : ''">
              {{ $dict.getData($dict.policyStatus, scope.row.policyStatus).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub1Status"
          label="科目一考试状态"
          class-name="active-col1"
          column-key="1"
          width="125">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.examSub1Status == 4
                  ? 'green-font'
                  : scope.row.examSub1Status == 3
                  ? 'red-font'
                  : scope.row.examSub1Status == 1
                  ? 'blue-font'
                  : ''
              ">
              {{ $dict.getData($dict.examSubStatus, scope.row.examSub1Status).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub1Time"
          label="科目一约考日期"
          class-name="active-col1"
          column-key="1"
          width="125">
          <template slot-scope="scope">
            <span v-if="scope.row.examSub1Status == 2">
              {{ $utils.formatDate(scope.row.examSub1Time) }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="active-col1"
          column-key="1"
          show-overflow-tooltip
          prop="examSub1FailNum"
          label="未通过次数"
          width="120">
          <template slot-scope="scope">
            {{ scope.row.examSub1FailNum ? scope.row.examSub1FailNum : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub2Status"
          label="科目二考试状态"
          class-name="active-col2"
          column-key="2"
          width="125">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.examSub2Status == 4
                  ? 'green-font'
                  : scope.row.examSub2Status == 3
                  ? 'red-font'
                  : scope.row.examSub2Status == 1
                  ? 'blue-font'
                  : ''
              ">
              {{ $dict.getData($dict.examSubStatus, scope.row.examSub2Status).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub2Time"
          label="科目二约考日期"
          class-name="active-col2"
          column-key="2"
          width="125">
          <template slot-scope="scope">
            <span v-if="scope.row.examSub2Status == 2">
              {{ $utils.formatDate(scope.row.examSub2Time) }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub2FailNum"
          label="未通过次数"
          class-name="active-col2"
          column-key="2"
          width="120">
          <template slot-scope="scope">
            {{ scope.row.examSub2FailNum ? scope.row.examSub2FailNum : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub3Status"
          label="科目三考试状态"
          class-name="active-col3"
          column-key="3"
          width="125">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.examSub3Status == 4
                  ? 'green-font'
                  : scope.row.examSub3Status == 3
                  ? 'red-font'
                  : scope.row.examSub3Status == 1
                  ? 'blue-font'
                  : ''
              ">
              {{ $dict.getData($dict.examSubStatus, scope.row.examSub3Status).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub3Time"
          label="科目三约考日期"
          class-name="active-col3"
          column-key="3"
          width="125">
          <template slot-scope="scope">
            <span v-if="scope.row.examSub3Status == 2">
              {{ $utils.formatDate(scope.row.examSub3Time) }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub3FailNum"
          label="未通过次数"
          class-name="active-col3"
          column-key="3"
          width="120">
          <template slot-scope="scope">
            {{ scope.row.examSub3FailNum ? scope.row.examSub3FailNum : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub4Status"
          label="科目四考试状态"
          class-name="active-col4"
          column-key="4"
          width="125">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.examSub4Status == 4
                  ? 'green-font'
                  : scope.row.examSub4Status == 3
                  ? 'red-font'
                  : scope.row.examSub4Status == 1
                  ? 'blue-font'
                  : ''
              ">
              {{ $dict.getData($dict.examSubStatus, scope.row.examSub4Status).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub4Time"
          label="科目四约考日期"
          class-name="active-col4"
          column-key="4"
          width="125">
          <template slot-scope="scope">
            <span v-if="scope.row.examSub4VenueCode == 9999999">任意时间</span>
            <span v-else-if="scope.row.examSub4Status == 2">
              {{ $utils.formatDate(scope.row.examSub4Time) }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="examSub4FailNum"
          label="未通过次数"
          class-name="active-col4"
          column-key="4"
          width="120">
          <template slot-scope="scope">
            {{ scope.row.examSub4FailNum ? scope.row.examSub4FailNum : '--' }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="contractNum" label="会员服务产品数量" width="150"></el-table-column> -->

        <el-table-column label="操作" width="200" fixed="right" v-if="$utils.hasPermission([20003, 20004])">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link v-p="[20003]" size="medium" type="primary" @click="openDetail(scope.row)">查看</el-link>
              <el-link
                v-p="[20004]"
                v-if="scope.row.policyStatus == 1"
                size="medium"
                type="primary"
                @click="openPolicy(scope.row)">
                服务查看
              </el-link>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {drvStudentGetList, drvSchoolGetSchool, drvStudentExportStudentList} from '@/api/index';
import tableMinx from '@/mixin/table-page';

export default {
  mixins: [tableMinx(drvStudentGetList)],
  data() {
    return {
      drvSchoolGetSchool,
      colKey: '',
      dateValue: [],
      loading: false,
      vehicleType: [''],
      vehicleTypeOptions: [
        {value: '', label: '全部'},
        {
          value: '1',
          label: '科目一',
          children: JSON.parse(JSON.stringify(this.$dict.examSubStatus)),
        },
        {
          value: '2',
          label: '科目二',
          children: JSON.parse(JSON.stringify(this.$dict.examSubStatus)),
        },
        {
          value: '3',
          label: '科目三',
          children: JSON.parse(JSON.stringify(this.$dict.examSubStatus)),
        },
        {
          value: '4',
          label: '科目四',
          children: JSON.parse(JSON.stringify(this.$dict.examSubStatus)),
        },
      ],
      tableQuery: {
        drvStudentName: '',
        drvStudentIdentityCard: '',
        vehicleType: '',
        examSub1Status: '',
        examSub2Status: '',
        examSub3Status: '',
        examSub4Status: '',
        policyStatus: '',
        enrollStartTime: '',
        enrollEndTime: '',
        transferStatus: null,
        transferStatusText: '',
        residence: '',
      },
    };
  },
  mounted() {},
  watch: {
    'tableQuery.transferStatusText': function (newVal) {
      if (newVal == 1) {
        this.tableQuery.transferStatus = true;
      } else if (newVal == 2) {
        this.tableQuery.transferStatus = false;
      } else {
        this.tableQuery.transferStatus = null;
      }
    },
  },
  methods: {
    // 日期选中
    datePickerChange() {
      if (this.dateValue) {
        this.tableQuery.enrollStartTime = Date.parse(new Date(this.dateValue[0])) / 1000;
        this.tableQuery.enrollEndTime = Date.parse(new Date(this.dateValue[1])) / 1000;
      } else {
        this.tableQuery.enrollStartTime = '';
        this.tableQuery.enrollEndTime = '';
      }
    },

    // 导出列表
    openExport() {
      if (this.tableData.total > 15000) {
        this.$message.error('最大导出数量为15000条,请修改查询条件后重新导出！');
        return false;
      }
      this.loading = true;
      drvStudentExportStudentList(this.tableQuery).then((res) => {
        this.loading = false;
        if (res.status == 200) {
          this.$utils.downloadFile(res);
          this.$message.success('导出考生信息成功！');
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    mouseLeave() {
      this.$refs.studentContainer.style.removeProperty('--col1-color');
      this.$refs.studentContainer.style.removeProperty('--col2-color');
      this.$refs.studentContainer.style.removeProperty('--col3-color');
      this.$refs.studentContainer.style.removeProperty('--col4-color');
    },

    mouseEnter(row, col) {
      this.$refs.studentContainer.style.removeProperty('--col1-color');
      this.$refs.studentContainer.style.removeProperty('--col2-color');
      this.$refs.studentContainer.style.removeProperty('--col3-color');
      this.$refs.studentContainer.style.removeProperty('--col4-color');
      if (col.columnKey) {
        this.$refs.studentContainer.style.setProperty('--col' + col.columnKey + '-color', '#eff6ff');
      }
    },

    vehicleTypeChange(valPath) {
      this.tableQuery.examSub1Status = '';
      this.tableQuery.examSub2Status = '';
      this.tableQuery.examSub3Status = '';
      this.tableQuery.examSub4Status = '';
      if (valPath[0] == '') {
        return false;
      }
      this.tableQuery['examSub' + valPath[0] + 'Status'] = valPath[1];
    },

    async openDetail(row) {
      let vNode = this.$createElement((await import('./detail')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '学员详情',
        width: '1200px',
      });
    },

    async openPolicy(row) {
      let vNode = this.$createElement((await import('./policy-detail')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '会员服务详情',
        width: '1200px',
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      data.forEach((item) => {
        if (item.examSub1Status == 1 && (item.examSub2Status != 1 || item.examSub3Status != 1 || item.examSub4Status != 1)) {
          item['transferStatus'] = true;
        } else if (
          item.examSub4Status != 1 &&
          (item.examSub1Status == 1 || item.examSub2Status == 1 || item.examSub3Status == 1)
        ) {
          item['transferStatus'] = true;
        } else {
          item['transferStatus'] = false;
        }
      });
      return data;
    },
  },
};
</script>

<style lang="scss">
.student-list-container {
  .red-font {
    color: $--color-danger;
  }
  .green-font {
    color: $--color-success;
  }
  .yellow-font {
    color: $--color-warning;
  }
  .blue-font {
    color: $--color-primary;
  }
  .hover-row {
    .active-col1 {
      background: #f6f7fb !important;
    }
    .active-col2 {
      background: #f6f7fb !important;
    }
    .active-col3 {
      background: #f6f7fb !important;
    }
    .active-col4 {
      background: #f6f7fb !important;
    }
  }
  .active-col1 {
    background: var(--col1-color);
  }
  .active-col2 {
    background: var(--col2-color);
  }
  .active-col3 {
    background: var(--col3-color);
  }
  .active-col4 {
    background: var(--col4-color);
  }
}
</style>
